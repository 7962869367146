import axios from 'axios';
import {IStore} from '../types/IStore';
import { server, maintenance } from "./Clients";
import {IDictionariesResponse} from '../types/IDictionariesResponse';
import {INewStore} from '../types/INewStore';
import {IContactForm} from '../types/IContactForm';
import { IStoreDetails } from '../types/IStoreDetails';
import { ISearchRequest } from '../types/ISearchRequest';
  
interface StoresResponse {
    stores: IStore[];
    allStoresCount : number;
  }

const apiClient = axios.create({
    baseURL: server
});

const maintenanceApiClient = axios.create({
  baseURL: maintenance
});

export const GetStores=() => apiClient.get<StoresResponse>('/store').then(response => response.data.stores);

export const GetFilteredStores=(searchRequest: ISearchRequest) => 
      apiClient.post<StoresResponse>('/store/_search',searchRequest)
        .then(response => response.data);

export const GetStoreByUrlName=(urlName: string) => apiClient.get<IStoreDetails>(`/store/${urlName}`).then(response => response.data);
export const GetStoreById=(id: string) => apiClient.get<IStoreDetails>(`/store/id/${id}`)
.then(response => response.data);

export const GetDictionaries=() => apiClient.get<IDictionariesResponse>('/store/dictionaries').then(response => response.data);

export const SendQuestion = (question: IContactForm) => apiClient.post('/contact', question);


export const AddStore = (store: INewStore) => maintenanceApiClient.post('/maintenance', store);
export const DeleteStore = (id: string) => maintenanceApiClient.delete(`/maintenance/${id}`);
export const UpdateStore = (store: INewStore) => maintenanceApiClient.put(`/maintenance`, store);
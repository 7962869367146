import React from 'react';
import './seo.css';
import categoriesData from './categories.seo.json';
import {ISeoDictionaries} from './seo.models';

const Seo: React.FC = () => {
  
  const data: ISeoDictionaries = categoriesData;

  return (
    <div className="seoSection">
      <div className='seoContent'>
        <div className="seoDescription">
          <h2 className="sectionTitle">Polskie marki odzieżowe w jednym miejscu</h2>
          <p className="typicalDescription">Kupuj lokalnie. Wspieraj polskich producentów ubrań.</p>
          <p>
          <b>Polskie marki odzieżowe</b> zyskują na popularności. Konsumenci coraz częściej zwracają uwagę na proces produkcyjny ubrania. Polskie ubrania są coraz chętnie poszukiwane na rynku, a rynek polskich firm odzieżowych zyskuje na popularności.
            <br />
            Ubrania <b>wyprodukowane w Polsce</b> kojarzą się z dobrą jakością i wspieraniem lokalności. Ze względu na krótszy łańcuch dostaw, doceniany jest też mniejszy wpływ produkcji ubrań na środowisko.
            <br /><br />
            Polskie sukienki, t-shirty, dresy, spodnie, czapki, akcesoria są często wybierane przez konsumentów.
            <br /><br />
            Dużą zaletą <b>polskich marek odzieżowych</b> jest większa transparentność procesu produkcyjnego. Tym co wyróżnia polskie marki odzieżowe jest także możliwość spotkania się z producentami osobiście na różnego rodzaju wydarzeniach, targach co w przypadku sieciówek jest wręcz niemożliwe.
          </p>
        </div>
        <div className="filterChips">
          <div className="chipGroup" data-test="gender">
            <p>Przeglądaj polskie firmy produkujące:</p>
            {data.genders.map((category) => (
              <a 
                key={category.name} 
                href={`/filtry/${category.code};g`} 
                className="seoChips" 
                target="_blank" 
                rel="noopener noreferrer" 
                aria-label={category.ariaLabel}
              >
                {category.name}
              </a>
            ))}
          </div>
          <div data-test="clothesType">
            <div className="chipGroup">
              <p>Przeglądaj:</p>
              {data.clothesTypes.map((category) => (
                <a 
                  key={category.name} 
                  href={`/filtry/${category.code};tc`} 
                  className="seoChips" 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  aria-label={category.ariaLabel}
                >
                  {category.name}
                </a>
              ))}
            </div>
          </div>
          {/* <div className="chipGroup" data-test="stores">
            <p>POPULARNE POLSKIE FIRMY</p>
            <a href='' className="chipButton" target="_blank" rel="noopener noreferrer">
              Jakiś tekst
            </a>
            <a href='' className="chipButton" target="_blank" rel="noopener noreferrer">
              Jakiś tekst
            </a>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Seo;

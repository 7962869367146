
const NotFound = () => {
  return (
    <div style={{paddingTop:'30px'}}>
      <h1>404 - Strona nie znaleziona</h1>
      <p style={{textAlign:'center'}}>Przepraszamy, ale strona, której szukasz, nie istnieje.</p>
    </div>
  );
};

export default NotFound;